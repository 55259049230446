<template>
    <div id="map" ref="mapContainer"></div>
</template>

<script>
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import * as turf from '@turf/turf';
import settingMap from '@/utils/map/settingMap'
import distanceRoute from '@/utils/distanceRoute/index'
import {deleteMapContributors, getSvgWithColor, svgStringToImageSrc} from '@/utils/map/deleteMapContributors'
  export default {
    props: ['orders','orderPerformers','iscircleRadius','isActivePerformens','division'],
    data() {
      return {
        map: null,
        is_free: true,
        markerCar: [],
        markerOrder: [],
        text: null,
        orderIdRemove: {
          id: null
        },
        circleRadius: 1,
        markerHome: [],
        dataOrder: [],
        performerID: {
          id: null
        },
        orderData: {}
      }
    },
    mounted() {
      this.orderMap()
      deleteMapContributors()
    },
    methods: {
      orderMap() {
        this.map = new maplibregl.Map({
              container: this.$refs.mapContainer,
              style: settingMap.map.style,
              zoom: settingMap.map.zoom,
              maxZoom: settingMap.map.maxZoom,
              minZoom: settingMap.map.minZoom,
              center: [this.division.lng, this.division.lat],
          });
          this.map.on('moveend', () => {
            this.map.getLayer('route') ? '' : this.clear()
            let bounds = this.map.getBounds()
            this.$emit('mapGetBounds', bounds)
            this.pefrormerIcon()
            this.orderIcon(this.orders)
          })
      },
      pefrormerIcon() {
        if(this.is_free) {
          for (let i = 0; i < this.orderPerformers.length; i++) {
            if(this.orderPerformers[i].is_free == 0) {
              this.orderPerformers[i].status_color = 'cornsilk'
            } 
          }
          this.createMarkerPerformer()
        }else {
          this.$emit('getPerformers')
          this.createMarkerPerformer()
        }
      },
      createMarkerPerformer() {
        this.orderPerformers.forEach((e) => {
          const svgString = getSvgWithColor(e.status_color, 'performer');
          const svgImage = new Image();
          svgImage.src = svgStringToImageSrc(svgString);

          svgImage.onload = () => {
            const imageName = `custom-svg-performer${e.id}`
            if(this.map.hasImage(imageName)) {
              this.map.removeImage(imageName);
            }
            this.map.addImage(imageName, svgImage);
            
            const markerElement = document.createElement('div')
            markerElement.appendChild(svgImage)
            markerElement.dataset.orderID = e.id

            const markerCar = new maplibregl.Marker({ 
                element: markerElement 
            })
            .setLngLat([e.lng, e.lat])
            .addTo(this.map);

            this.markerCar.push(markerCar)

            markerElement.addEventListener("click",() => {
              this.routerPerformer(e)
            })

            markerElement.addEventListener('dblclick', () => {
              this.clearPerformer(e)
            })
          };
        });
      },
      routerPerformer(e) {
        this.performerID = {
          id: null
        }
        if(this.dataOrder[0]) {
          if(this.performerID.id == null) {
              this.conditions(e)
          }
          else if(e.id !== this.performerID.id) {
                this.clear(e)
                this.performerID = {
                  id: null
                },
                this.conditions(e)
          }
        }else {
          this.$emit("editPerformers", e)
          this.performerID = e
        }
      },
      conditions(e) {
        let points = [
            {
                lng: e.lng,
                lat: e.lat,
            },
            {
                lng: this.dataOrder[0].from_address.lng,
                lat: this.dataOrder[0].from_address.lat,
            }
          ];
          distanceRoute(points, this)
          .then((res) => {
            this.createRoute(res.data.route, e.id.toString(), '#888')
          })
          .catch((error) => {
            console.error(error);
          })
          this.$emit("editPerformers", e)
          this.performerID = e
      },
      clearPerformer(e) {
        this.$emit("editPerformers", '')
        this.clear(e)
        this.performerID.id = null
      },
      orderIcon(orders) {
        orders.forEach((e) => {
          const svgString = getSvgWithColor(e.status_color, 'order')
          const svgImage = new Image();
          svgImage.src = svgStringToImageSrc(svgString)

          svgImage.onload = () => {
            const imageName = `custom-svg-order${e.id}`
            if(this.map.hasImage(imageName)) {
              this.map.removeImage(imageName);
            }
            this.map.addImage(imageName, svgImage);

            const markerElement = document.createElement('div')
            markerElement.appendChild(svgImage)
            markerElement.dataset.orderID = e.id

            const markerOrder = new maplibregl.Marker({
              element: markerElement
            })
            .setLngLat([e.lng, e.lat])
            .addTo(this.map);

            this.markerOrder.push(markerOrder)

            markerElement.addEventListener("click",() => {
              if(e.id !== this.orderIdRemove.id) {
                this.createOrderRoute(e)
              }
            })
            
            markerElement.addEventListener('dblclick', () => {
              this.clear(e)
              this.clearOrderHome()
              this.cleaRadiusOrder()
              if(this.performerID.id) this.clear(this.performerID), this.$emit("editPerformers", ''), this.performerID.id = null
              if(this.orderIdRemove.id) this.orderIdRemove.id = null, this.$emit('editOrder', '')
            })
          }
        })
      },
      async createOrderRoute(e) {
        this.orderData = e
        this.orderIdRemove = e
        let editOrderDataGeoJsonArray = await this.editOrderDataGeoJsonArray(e)
        this.dataOrder.push(editOrderDataGeoJsonArray)
        let points = [
          {
              lng: e.lng,
              lat: e.lat,
          },
        ];
        this.dataOrder[0].to_addresses.forEach((order) => {
          points.push({lng: order.lng, lat: order.lat})
        })
        
        this.$emit('editOrder', e)
        this.createCircleOrder(e)
        distanceRoute(points, this)
        .then((res) => {
          this.createRoute(res.data.route, e.id.toString(), '#4ae81a')
          this.createMarkerOrderHome(this.dataOrder[0])
          this.editColorOrder(this.dataOrder[0])
        })
        .catch((error) => {
          console.error(error);
        })
      },
      editColorOrder(order) {
        this.orders.forEach((e) => {
          if(order.id === e.id) {
            this.orders.status_color = 'green'
          }else {
            this.orders.status_color = 'red'
          }
        })
      },
      createMarkerOrderHome(data) {
        data.to_addresses.forEach((e) => {
          const el = document.createElement('div');
          el.style.width = '56px';
          el.style.height = '56px';
          el.style.backgroundImage = `url(${settingMap.map.home})`;
          el.style.backgroundSize = 'cover';
          let markerHome = new maplibregl.Marker({element: el})
              .setLngLat([e.lng, e.lat])
              .addTo(this.map)
          this.markerHome.push(markerHome)
        });
      },
      createRoute(data, e, style) {
        this.map.addSource(`route-${e}`, {
            type: 'geojson',
            data: {
                'type': 'Feature',
                'geometry': {
                    'type': 'LineString',
                    'coordinates': data
                },
            },
        });
        this.map.addLayer({
            'id': `route-${e}`,
            'type': 'line',
            'source': `route-${e}`,
            'layout': {
                'line-join': 'round',
                'line-cap': 'round',
            },
            'paint': {
                'line-color': `${style}`,
                'line-width': 8
            }
        });
      },
      createCircleOrder(e) {
          var options = {
              steps: 64,
              units: "kilometers",
            }
          this.circleOrder = turf.circle([e.lng,e.lat], this.circleRadius, options)
  
          this.map.addLayer({
            id: "location-radius",
            type: "fill",
            source: {
              type: "geojson",
              data: this.circleOrder,
            },
            paint: {
              "fill-color": "#8CCFFF",
              "fill-opacity": 0.5,
            },
          });
  
          this.map.addLayer({
            id: "location-radius-outline",
            type: "line",
            source: {
              type: "geojson",
              data: this.circleOrder,
            },
            paint: {
              "line-color": "#0094ff",
              "line-width": 3,
            },
          });
      },
      editOrderDataGeoJsonArray(e) {
        return this.$http
            .get(`orders-map/orders/${e.id}/show`)
            .then((res) => {return res.data})
      },
      clearOrderHome() {
        if(this.markerHome) this.markerHome.forEach((e) => e.remove())
      },
      cleaRadiusOrder() {
        if(this.map.getLayer("location-radius") && this.map.getLayer("location-radius-outline")) {
            this.map.removeLayer('location-radius')
            this.map.removeLayer('location-radius-outline')
            this.map.removeSource("location-radius");
            this.map.removeSource('location-radius-outline')
          }
      },
      clear(e) {
        if(e) {
          const text = e.id.toString()
          if(this.map.getLayer(`route-${text}`)) {
            this.map.removeLayer(`route-${text}`)
            this.map.removeSource(`route-${text}`)
            this.dataOrder = []
          }
        }
        if(this.markerOrder.length) this.markerOrder.forEach((e) => e.remove())
        if(this.markerCar.length) this.markerCar.forEach((e) => e.remove())
        this.markerCar = []
        this.markerOrder = []
      }
    },
    created() {
      this.$root.$on('callClearClickInOtherComponent', () => {
        this.clear(this.orderData)
        this.cleaRadiusOrder()
        this.clearOrderHome()
      })
    },
    watch: {
      division(newValue) {
        this.map.jumpTo({'center': [newValue.lng, newValue.lat], 'zoom': settingMap.map.zoom})
        this.clear(this.performerID)
        this.clear(this.orderData)
        this.clearOrderHome()
        this.cleaRadiusOrder()
      },
      isActivePerformens(newValue) {
        this.is_free = newValue
        this.pefrormerIcon(newValue)
      },
      iscircleRadius(newValue) {
        this.circleRadius = newValue
        if(this.circleRadius !== 1) {
          this.cleaRadiusOrder()
          this.createCircleOrder(this.orderData)
        }
      }
    }
   }
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: calc(100vh - 93px)  !important;
}
</style>


