<template>
    <div class="mapModalOrder  border rounded" style="width: 600px;">
        <p class="ml-1">Подразделение:</p>
        <div class="d-flex">
            <v-select class="ml-1 w-50" placeholder="Подразделение" label="name" :reduce="options => options"
                :options="orderMap.divisions" v-model="division_id" @input="getOpenMap($event)">
                <template #no-options>
                    <span>
                        Данные отсутствуют!
                    </span>
                </template>
            </v-select>
            <b-button style="height: 40px; padding: 3px; margin-left: 20px;" variant="primary" @click="update()">
                Обновить карту
            </b-button>
            <b-button style="margin-left: 20px; height: 40px;" variant="primary">?</b-button>
        </div>
        <b-col class="mt-2">
            <b-form-checkbox v-model="isActivePerformen" @input="getActivePerformer($event)">Скрыть водителей на
                заказе</b-form-checkbox>
        </b-col>
        <hr class="mt-2">
        <b-col class="w-50">
            <p>Радиус заказа (км):</p>
            <b-form-input type="number" :disabled="isRadiusActive" v-model.number="isRadius"
                @keyup.enter="getRadiusId"></b-form-input>
        </b-col>
        <div class="order border border-black rounded">
            <p v-if="isActiveOrder" @click="getOrder()" class="orderColor">Провалиться в заказе</p>
            <h4>Информамация о заказе</h4>
            <div v-if="isActiveOrder">
                <p>ID: {{ orderDirvers.id }}</p>
                <p>Тариф: {{ orderDirvers.tariff }}</p>
                <p>Статус: {{ orderDirvers.status }}</p>
                <p>Цена водителя: {{ orderDirvers.price }}</p>
                <p>ВП: {{ orderDirvers.past_minute }}</p>
                <p>Расстояние: {{ orderDirvers.distance }}</p>
                <p>Расстояние(По городу): {{ orderDirvers.distance_in_city }}</p>
                <p>Расстояние(Межгород): {{ orderDirvers.search_address_id }}</p>
                <p>Холостой: {{ orderDirvers.free_km }}</p>
                <p>Маршрут:</p>
                <p>><span v-if="orderDirvers.from_address">
                    <span
                        v-if="orderDirvers.from_address.type === 'fast_address' || orderDirvers.from_address.type === 'address_point'">
                        {{ orderDirvers.from_address.name }}
                    </span>
                    <span v-if="orderDirvers.from_address.type === 'address'">
                        <span v-if="orderDirvers.from_address.street_type == 'микрорайон'">
                            <span>
                                {{ orderDirvers.from_address.street + ' ' + orderDirvers.from_address.street_type + ', ' + orderDirvers.from_address.name }}
                            </span>
                        </span>
                        <span v-else>
                            <span>
                                {{ orderDirvers.from_address.street_type + ' ' + orderDirvers.from_address.street + ', ' + orderDirvers.from_address.name }}
                            </span>
                        </span>
                    </span>
                </span>{{ orderDirvers.meeting_info ? `(${orderDirvers.meeting_info})` : '' }}</p>
                <p>> <span v-for="(to_address, idx) in orderDirvers.to_addresses" :key="idx">
                        <span v-if="to_address">
                            <span v-if="to_address.type === 'fast_address' || to_address.type === 'address_point'">
                                {{ to_address.name + ';' }}
                            </span>
                            <span v-if="to_address.type === 'address'">
                                <span v-if="to_address.street_type == 'микрорайон'">
                                    <span>
                                        {{ to_address.street + ' ' + to_address.street_type + ', ' + to_address.name + ';' }}
                                    </span>
                                </span>
                                <span v-else>
                                    <span>
                                        {{ to_address.street_type + ' ' + to_address.street + ', ' + to_address.name + ';' }}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span></p>
                <p>{{ `${orderDirvers.info_for_operator ? orderDirvers.info_for_operator : ''} ${orderDirvers.info_for_drivers ? orderDirvers.info_for_drivers : ''} ` }}</p>
            </div>
        </div>
        <div class="d-flex justify-content-between m-1" v-if="isActiveOrder">
            <b-button variant="primary" class="button_call" @click="getCallClient()">Позвонить клиенту</b-button>
            <b-button :disabled="isOrderActivePerformen" variant="primary" class="button_assign">Отменить
                заказ</b-button>
        </div>
        <div class="order border border-black rounded">
            <p v-if="isActive" @click="getPerformen()" class="orderColor">Провалиться с автомобиль</p>
            <h4>Информамация о водителе</h4>
            <div v-if="isActive">
                <p>ФИО: {{ performerExecutor.first_name + ' ' + performerExecutor.last_name + ' ' +
                performerExecutor.patronymic
                    }}</p>
                <p>Логин: {{ performerExecutor.login }}</p>
                <p>Гос.номер: {{ performerExecutor.driver_cars[0].car_number }}</p>
                <p>Телефон: {{ performerExecutor.phone }}</p>
                <p>Статус: {{ performerExecutor.status }}</p>
                <p>Автомобиль: {{ performerExecutor.driver_cars[0].car_brand + ' ' +
                performerExecutor.driver_cars[0].model + '' + performerExecutor.driver_cars[0].color }}</p>
                <p>Год выпуска: {{ performerExecutor.driver_cars[0].year_of_issue }}</p>
                <p>Класс авто: {{ performerExecutor.driver_cars[0].class_car }}</p>
                <p>Категория авто: {{ performerExecutor.driver_cars[0].category }}</p>
                <p>Подразделение: {{ performerExecutor.driver_cars[0].division }}</p>
                <p>Простой(мин): {{ }}</p>
                <p>Скорость (км/ч): {{ }}</p>
            </div>
        </div>
        <div v-if="is_free === 1">
            <div class="d-flex justify-content-between m-1" v-if="isActive">
                <b-button variant="primary" class="button_call" @click="getCallDriver()">Позвонить водителю</b-button>
                <b-button variant="primary" class="button_assign" :disabled="isDisabledOrder || isDisabledPerformer"
                    @click="assignDriver()">Назначить водителя</b-button>
            </div>
        </div>
        <div class="order border border-black rounded">
            <h4>Маршрут до заказа</h4>
            <div v-if="isActiveOrder">
                <p>Расстояние(По городу):</p>
                <p>{{ orderDirvers.free_km }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
        ToastificationContent
    },
    props: ['orderMap', 'order', 'performers', 'division'],
    data() {
        return {
            division_id: this.division,
            isActive: false,
            isActiveOrder: false,
            isActivePerformen: true,
            isOrderActivePerformen: true,
            isDisabledOrder: true,
            isDisabledPerformer: true,
            isRadius: 1,
            isRadiusActive: true,
            is_free: '',
            phone: '',
            orderDirvers: [],
            performerExecutor: [],
            destination: {
                performer_id: this.performers,
                order_id: this.order,
            },
        }
    },
    watch: {
        order(newValue) {
            if(newValue == '') {
                this.isActiveOrder = false
                this.isRadiusActive = true
            }else {
                this.isActiveOrder = false
                this.isRadiusActive = true
                this.destination.order_id = newValue.id
                this.$http
                    .get(`orders-map/orders/${newValue.id}/show`)
                    .then(res => {
                        this.getRadiusId()
                        this.orderDirvers = res.data
                        this.isDisabledOrder = false
                        this.isActiveOrder = true
                        this.isRadiusActive = false
                    })
            }
        },
        performers(newValue) {
            if(newValue == '') {
                this.isActive = false
            }else {
                this.isActive = false
                this.destination.performer_id = newValue.id
                this.$http
                    .get(`orders-map/performers/${newValue.id}/show`)
                    .then(res => {
                        this.performerExecutor = res.data
                        this.is_free = newValue.is_free
                        this.isDisabledPerformer = false
                        this.isActive = true
                    })
            }
        },
    },
    methods: {
        assignDriver() {
            this.isDisabledOrder = true
            if (this.order && this.performers) {
                this.$http
                    .post("orders-map/orders/order-destination", this.destination)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Успех!',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: res.data.message,
                            },
                        })
                        this.update()
                        this.getClear()
                        this.isActiveOrder = false
                        this.isActive = false
                        this.isDisabledOrder = false
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-rigth',
                            props: {
                                title: 'Некорректные данные!',
                                icon: 'XIcon',
                                variant: 'danger',
                                text: err.message,
                            }
                        })
                    }).finally(() => {
                        this.isDisabledOrder = false;
                    });
            }
        },
        getActivePerformer($event) {
            this.isActivePerformen = $event
            this.$emit('getIsActiveFree', this.isActivePerformen)
        },
        getOpenMap($event) {
            let division = $event
            this.isActiveOrder = false
            this.isActive = false
            this.$emit('getOpenMap', division)
        },
        getRadiusId() {
            this.$emit('getRadiusId', this.isRadius)
        },
        update() {
            this.$emit('refresh')
            this.$emit('getPerformers')
            this.$root.$emit('callClearClickInOtherComponent');
            this.isRadiusActive = true
        },
        getOrder() {
            this.$router.push({ name: 'infoOrderMap', query: { order: this.order.id } })
            this.$store.commit('draggableTab/ADD_TAB', ['Закази ' + this.order.id, `/infoOrderMap`, this.order.id])
        },
        getPerformen() {
            this.$router.push({ name: "carInfo", query: { filter: this.performers.id } });
            this.$store.commit("draggableTab/ADD_TAB", ["Автомобиль " + this.performers.id, `/driver/carInfo`, this.performers.id,]);
        },
        getCallClient() {
            if (this.$store.state.caller.status) {
                let initiatorNumber = JSON.parse(localStorage.getItem('sipConfig')).user
                this.$http.post('/connect-driver-operator', {
                    number: initiatorNumber,
                    phone: this.orderDirvers.phone,
                })
                    .then(() => {
                        this.$store.commit('caller/UPDATE_IS_INITIATOR_STATE', true)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Запрос на соединение отправлен',
                                icon: 'XIcon',
                                variant: 'success',
                                text: "",
                            },
                        })
                    })
                    .catch((err) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: "Ошибка",
                                icon: 'XIcon',
                                variant: 'danger',
                                text: err,
                            },
                        })
                    })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Включите SIP телефонию',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "",
                    },
                })
            }
        },
        getCallDriver() {
            if (this.$store.state.caller.status) {
                let initiatorNumber = JSON.parse(localStorage.getItem('sipConfig')).user
                this.$http.post('/connect-driver-operator', {
                    number: initiatorNumber,
                    phone: this.performerExecutor.phone
                })
                    .then(() => {
                        this.$store.commit('caller/UPDATE_IS_INITIATOR_STATE', true)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Запрос на соединение отправлен',
                                icon: 'XIcon',
                                variant: 'success',
                                text: "",
                            },
                        })
                    })
                    .catch((err) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: "Ошибка",
                                icon: 'XIcon',
                                variant: 'danger',
                                text: err,
                            },
                        })
                    })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Включите SIP телефонию',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "",
                    },
                })
            }
        },
        getClear() {
            this.isActive = false,
            this.isActiveOrder = false,
            this.isActivePerformen = true,
            this.isOrderActivePerformen = true,
            this.isDisabledOrder = true,
            this.isDisabledPerformer = true,
            this.isRadiusActive = true
        }
    },
    created() {
        this.$root.$on('isRadiusActiveTrue', (is_active) => {
            this.isRadiusActive = is_active
        })
    }
}
</script>

<style scoped>
.mapModalOrder {
    width: 50%;
    height: 760px;
    overflow: auto;
}

.order {
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 10px;
}

.button_call {
    width: 200px;
    height: 40px;
}

.button_assign {
    width: 200px;
    height: 40px;
}

.orderColor {
    color: blue;
    font-size: 15px;
    cursor: pointer;
}
</style>