<template>
    <div>
      <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered"/>
      </div>
      <div v-else class="d-flex justify-content-center">
        <map-order @editPerformers="editPerformers" @editOrder="editOrder" :division="division" @mapGetBounds="mapGetBounds" :orders="orders" :orderPerformers="orderPerformers" :isActivePerformens="isActivePerformens" @getPerformers="getPerformers" :iscircleRadius="iscircleRadius"></map-order>
        <map-order-infomation :performers="performers" :order="order" :division="division" :orderMap="orderMap" @getOpenMap="getOpenMap" @refresh="refresh" @getPerformers="getPerformers" @getIsActiveFree="getIsActiveFree" @getRadiusId=getRadiusId></map-order-infomation>
      </div>
    </div>
</template>

<script>
import socket from "@/utils/socket/socket.js"
import mapOrder from '@/views/component/Modal/ModalOrderMap/mapOrder.vue'
import mapOrderInfomation from '@/views/component/Modal/ModalOrderMap/mapOrderInfomation.vue' 
export default {
components: {
  mapOrder,
  mapOrderInfomation,
}, 
data() {
  return{
   showPreloader: false,
   isActivePerformens: true,
   orders: [],
   orderMap: [],
   order: [],
   performers: "",
   orderPerformers: [],
   iscircleRadius: '',
   division: 
    {
     id:1,
     lat:"40.28256000",
     lng:"69.62216000",
     name:"Грам (Худжанд)",
     zoom:14,
    }
   ,
   bounds: [],
  }
},
mounted() {
  this.getOrdermap()
  this.socketMap()
  socket.open()
  this.$store.commit('pageData/setdataCount', null)
},
methods: {
  mapGetBounds(bounds) {
    let data = JSON.stringify({
      _southWest: {lat: bounds._sw.lat, lng: bounds._sw.lng},
      _northEast: {lat: bounds._ne.lat, lng: bounds._ne.lng}
    })
    this.bounds = data
    this.getOpenMap(this.division)
  },
  getOpenMap(division) {
    this.division = division
    this.getPerformers()
    this.refresh()
  },
  refresh() {
    this.$http
        .get(`orders-map/orders?division_id=${this.division.id}&bounds=${this.bounds}`)
        .then(res =>{
          this.orders = res.data
          this.$store.commit('pageData/setdataCount', this.orders.length)
          this.$store.commit('REFRESH_DATA', false)
        })
  },
  getOrdermap() {
    this.$http
        .get('orders/data')
        .then(res =>{
            this.orderMap = res.data;
        })
    },
  getPerformers() {
    this.$http
        .get(`orders-map/performers?division_id=${this.division.id}&bounds=${this.bounds}`)
        .then(res =>{
          this.orderPerformers = res.data
        })
  },
  getIsActiveFree(isActivePerformen) {
    this.isActivePerformens = isActivePerformen
  },
  getRadiusId(isRadius) {
    this.iscircleRadius = isRadius
  },
  editOrder(order) {
    this.order = order
  },
  editPerformers(performers) {
    this.performers = performers
  },
  socketMap() {

    // Order

    socket.on('admin-gram:order-map',(data)=>{
      // Event update order, create order, update status
      this.socketOrderStatus(data)
    });
    socket.on('admin-gram:order-map-remove-orders',(data)=>{
      // Event remove order
    	this.socketOrderRemove(data)
    });

    // Performers

    socket.on('admin-gram:order-map-performers',(data)=>{
      // Event update performers, create order, update status
      this.socketPerformerStatus(data)
    });
    socket.on('admin-gram:order-map-remove-performers',(data)=>{
      // Event remove performers
      this.socketPerformerRemove(data)
    })
  },
  socketOrderStatus(data) {
    let orderStatus = data.data
    if(this.orders.length > 0) {
      let self = this;
      let found = false;
      this.orders.forEach(function(val) {
        if(orderStatus.id === val.id) {
          val.is_free = orderStatus.is_free
          val.lat = orderStatus.lat
          val.lng = orderStatus.lng
          val.status_color = orderStatus.status_color
          found = true
        }
      });
     if(!found){
          self.orders.push(orderStatus)
        }
    }else{
      this.orders.push(orderStatus)
    }
  },
  socketOrderRemove(data) {
    let removeOrder = data.data.order_id
    this.orders = this.orders.filter(order => order.id !== removeOrder)
  },
  socketPerformerStatus(data) {
    let performerStatus = data.data
    if(this.orderPerformers.length > 0) {
      let self = this;
      let found = false;
      this.orderPerformers.forEach(function(val) {
        if(performerStatus.id === val.id) {
          val.id = performerStatus.id
          val.is_free = performerStatus.is_free
          val.lat = performerStatus.lat
          val.lng = performerStatus.lng
          val.status_color = performerStatus.status_color
          found = true
        }
      });
     if(!found){
          self.orderPerformers.push(performerStatus)
        }
    }else{
      this.orderPerformers.push(performerStatus)
    }
  },
  socketPerformerRemove(data){
    let removePerformer = data.data.performer_id
    this.orderPerformers = this.orderPerformers.filter(performer => performer.id !== removePerformer)
  }
},
computed: {
  fetchingNewData(){
    return this.$store.state.refresh.fetchingNewData
  }
},
watch: {
  fetchingNewData(val){
    if (val){
      this.$http
      .get(`orders-map/orders?division_id=${this.division.id}&bounds=${this.bounds}`)
      .then(res =>{
        this.orders = res.data
        this.$store.commit('pageData/setdataCount', this.orders.length)
        this.$store.commit('REFRESH_DATA', false)
      })
      this.getPerformers()
    }
  }
},
beforeDestroy() {
  socket.close()
},
}
</script>
